// extracted by mini-css-extract-plugin
export var container = "TeamMemberPageTemplate-module--container--uPaHC";
export var footer = "TeamMemberPageTemplate-module--footer--THYTz";
export var interview = "TeamMemberPageTemplate-module--interview--POIKF";
export var logo = "TeamMemberPageTemplate-module--logo--6Onhi";
export var profile = "TeamMemberPageTemplate-module--profile--NXHbE";
export var profileArrowLeft = "TeamMemberPageTemplate-module--profile-arrow-left--0pKxT";
export var profileArrowRight = "TeamMemberPageTemplate-module--profile-arrow-right--Jzkmg";
export var profileAvatar = "TeamMemberPageTemplate-module--profile-avatar--1GkrP";
export var profileContainer = "TeamMemberPageTemplate-module--profile-container--SzDZE";
export var profileDescription = "TeamMemberPageTemplate-module--profile-description--hzQTU";
export var profileLinks = "TeamMemberPageTemplate-module--profile-links--WWT9W";
export var profileOrnaments = "TeamMemberPageTemplate-module--profile-ornaments--slX7g";
export var profileRole = "TeamMemberPageTemplate-module--profile-role--n3fes";